(function () {
    'use strict';

    bindhq.ns('subjectivities');

    /**
     * @param {jQuery} container
     */
    bindhq.subjectivities.init = function (container) {
        const displayArea = container.get(0);

        bindhq.gondola.initSubjectivitiesClient(displayArea);
    };
})();
