(function () {
    'use strict';

    bindhq.nsIn('forms.applicationIndexForm', {
        /**
         * @param {jQuery} container
         * @param {Array} agencies
         */
        onProducerAgenciesLoaded: function (container, agencies) {
            $('.agencies', container).select2('data', agencies);
        },

        /**
         *
         */
        onProducerAgenciesComplete: function () {
            bindhq.loader.hide();
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onAgencyProducerChange: function (container, evt) {
            const target = $(evt.currentTarget);
            const producerId = target.val();
            const url = container.data('producer-agencies-url');
            const config = {
                type: 'GET',
                url: url.replace(/{producerId}/, producerId),
                success: _.partial(this.onProducerAgenciesLoaded, container),
                complete: this.onProducerAgenciesComplete,
            };

            $.ajax(config);

            bindhq.loader.show();
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            container.submit(function () {
                const tabValue = container.find("input[name^='tab']").val();

                if (tabValue === 'my_applications') {
                    $('#applicationFilter_users').prop('disabled', false);
                }
            });

            container.on(
                'change',
                '.agency-producer',
                _.partial(this.onAgencyProducerChange, container),
            );
        },
    });
})();
