(function () {
    'use strict';

    bindhq.ns('agencies');

    bindhq.agencies.redirectForm = function () {
        const form = $(this);
        form.find('a#as-csv').click(function (ev) {
            ev.preventDefault();
            form.append(
                "<input type='hidden' name='full_report' value='true'/>",
            );
            form.prop('action', '/secure/admin/agencies/csv');
            form.submit();
            form.prop('action', '/secure/admin/agencies/');
        });
    };

    bindhq.agencies.init = function () {
        $('form.agencies').each(bindhq.agencies.redirectForm);
    };
})();
