(function () {
    'use strict';

    bindhq.ns('forms.formpicker');

    /**
     * Fill the container with the specified markup
     *
     * @param {jQuery} container
     * @param {String} html
     */
    bindhq.forms.formpicker.fill = function (container, html) {
        container.removeClass('application-form-empty').empty().html(html);

        bindhq.forms.init.apply(container);
    };

    /**
     * A form picker control has changed, need to reload the form
     *
     * @param {jQuery} input
     * @param {jQuery} container
     */
    bindhq.forms.formpicker.reload = function (input, container) {
        const name = input.val();
        const form = input.parents('form');
        const appId = form.data('id');

        $.ajax({
            url: '/secure/forms/' + name + '?applicationId=' + (appId || ''),
            success: _.partial(bindhq.forms.formpicker.fill, container),
        });
    };

    /**
     * Initialise a form picker control
     *
     */
    bindhq.forms.formpicker.init = function () {
        const input = $(this);
        const fieldset = input.closest('.control-group');
        const container = $('<div></div>');
        const onChange = _.partial(
            bindhq.forms.formpicker.reload,
            input,
            container,
        );

        input.change(onChange);

        container
            .addClass('application-form application-form-empty')
            .insertAfter(fieldset);

        if (input.val()) {
            bindhq.forms.formpicker.reload(input, container);
        }
    };
})();
