(function () {
    'use strict';

    bindhq.ns('policies.balance');

    bindhq.policies.balance.display = function (container, data) {
        if (data) {
            const fields = ['total', 'paid', 'outstanding'];

            _.each(fields, function (field) {
                const value = data[field] || 0;
                const dollars = parseFloat(value).toFixed(2);

                $('.' + field + ' span', container)
                    .html('$' + dollars)
                    .removeClass('field-loading');
            });
        }
    };

    bindhq.policies.balance.init = function () {
        const container = $(this);
        const ns = bindhq.policies.balance;
        const onLoad = _.partial(ns.display, container);
        const url = container.data('url');

        bindhq.ajax.get(url, {}, { success: onLoad });
    };
})();
