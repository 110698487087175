const TomSelect = require('tom-select');

(function () {
    'use strict';

    bindhq.nsIn('abacus.transactionFilter', {
        /**
         * @param {TransactionTable}
         */
        table: null,

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} e
         */
        onPayableAction: function (container, e) {
            const payable = $(e.target).parent().siblings('.is-payable');
            const button = $(e.target);
            if (payable.length && $(payable).hasClass('not-payable')) {
                swal(
                    {
                        title: 'Hey!',
                        text: 'You are attempting to make a payment where the associated transaction has not been paid in full. \n \n Are you sure you wish to proceed?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                    },
                    function () {
                        if ($(e.target).data('href') !== '') {
                            button.progress('start');
                            location.href = $(e.target).data('href');
                        }
                    },
                );
            } else {
                if ($(e.target).data('href') !== '') {
                    button.progress('start');
                    location.href = $(e.target).data('href');
                }
            }
        },

        /**
         * @param {jQuery.Event} evt
         */
        onLoadAllClick: function (evt) {
            const criteria = {};
            const source = $(evt.currentTarget);

            criteria[source.attr('name')] = source.val();

            source
                .closest('.switch-field')
                .find('.transaction-filter-all')
                .removeClass('transaction-filter-all')
                .addClass('transaction-filter');

            this.table.addCriteria(criteria);
            this.table.loadAllTransactions();
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onPayableAction = _.partial(this.onPayableAction, container);

            container.on('click', '.payableAction', onPayableAction);

            this.table = bindhq.abacus.transactionTable.create(container);

            let firstLoad = true;

            container
                .get(0)
                .querySelectorAll('select')
                .forEach((element) => {
                    new TomSelect(element, { allowEmptyOption: true });
                });

            this.table.options.applyImmediately = false;
            this.table.indexFromDom('.transaction-item');
            this.table.applyFilters();

            container.on('transaction_table.items_loaded', () => {
                this.table.applyFilters();
            });

            container.on('click', '.btn-apply-transaction-filters', () => {
                container
                    .closest('.table-listing-wrapper')
                    .removeClass('table-listing-tools-on');

                if (firstLoad) {
                    firstLoad = false;

                    this.table.loadAllTransactions();
                } else {
                    this.table.applyFilters();
                }
            });

            bindhq.abacus.transactionMultiSelect.initContainer(container);

            container.on(
                'transaction_multi_select.offset.complete',
                function (evt) {
                    $(evt.target).empty();
                },
            );
        },
    });
})();
