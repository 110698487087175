(function () {
    ('use strict');

    bindhq.ns('tasks');

    bindhq.tasks.incomplete = $('.tasks-incomplete');

    /**
     * @type {Integer}
     */
    bindhq.tasks.THREE_MINUTES_IN_SECONDS = 180;

    /**
     * @type {Integer}
     */
    bindhq.tasks.CACHE_TIMEOUT = bindhq.tasks.THREE_MINUTES_IN_SECONDS;

    /**
     * @param {Number} total
     */
    bindhq.tasks.updateIncomplete = function (total) {
        const elem = bindhq.tasks.incomplete.html(total);

        if (total > 0) {
            elem.addClass('visible');
        } else {
            elem.removeClass('visible');
        }
    };

    bindhq.tasks.bindCloseCurrentModal = function () {
        $(document).on('shown.bs.modal', '.modal', function () {
            if ($(this).children('.modal-task-new').length) {
                $('.modal-tasks').parent().modal('hide');
            }
        });
    };

    /**
     * @param {Object} data
     */
    bindhq.tasks.onLoad = function (data) {
        bindhq.tasks.updateIncomplete(data.total);
        bindhq.tasks.bindCloseCurrentModal();
    };

    /**
     *
     */
    bindhq.tasks.init = function () {
        const self = bindhq.tasks;

        if (self.incomplete.length > 0) {
            const url = self.incomplete.data('url');
            const options = {
                timeout: self.CACHE_TIMEOUT,
                success: self.onLoad,
            };

            bindhq.ajax.get(url, {}, options);
        }
    };
})();
