(function () {
    'use strict';

    bindhq.ns('applications.documents');

    bindhq.applications.documents.viewer = $('<div></div>');

    bindhq.applications.documents.showDocument = function (evt) {
        const id = $(evt.currentTarget).data('file-id');
        const viewer = bindhq.applications.documents.viewer;
        const frame = $('<iframe></iframe>').attr('src', '/secure/files/' + id);

        $('iframe', viewer).remove();

        bindhq.ga.event('feature', 'app-doc-viewer', 'show');

        viewer.append(frame).show();
    };

    bindhq.applications.documents.closeViewer = function () {
        bindhq.applications.documents.viewer.hide();
    };

    bindhq.applications.documents.updateViewer = function (wrap) {
        const viewer = bindhq.applications.documents.viewer;
        const offsetTop = wrap.offset().top;
        const navHeight = 60;
        const scrollTop = $(document).scrollTop() + navHeight;

        viewer.animate({
            top: scrollTop > offsetTop ? scrollTop - offsetTop + 'px' : '0px',
        });
    };

    bindhq.applications.documents.initScroll = function () {
        const viewer = bindhq.applications.documents.viewer;
        const onScroll = _.partial(
            bindhq.applications.documents.updateViewer,
            viewer.parent(),
        );

        $(window).scroll(_.debounce(onScroll, 100));
    };

    bindhq.applications.documents.init = function () {
        const container = $(this);
        const documents = $('.files', container);
        const viewer = bindhq.applications.documents.viewer;
        const closer = $('<a></a>')
            .html('close viewer')
            .click(bindhq.applications.documents.closeViewer);

        viewer
            .addClass('documents-viewer')
            .append(closer)
            .hide()
            .insertAfter(container)
            .wrap('<div class="documents-wrap"></div>');

        documents.on(
            'click',
            'a[data-file-id]',
            bindhq.util.noDefault(bindhq.applications.documents.showDocument),
        );

        bindhq.applications.documents.initScroll(viewer);
    };
})();
