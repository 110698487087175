(function () {
    'use strict';

    bindhq.nsIn('offlineSearch', {
        /**
         *
         */
        reload: function () {
            location.reload();
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const timeout = container.data('refresh-timeout') || 5000;

            setTimeout(this.reload, timeout);
        },
    });
})();
