const FormValidator = require('../../../../../../../assets/js/BindHQ/Validation/FormValidator');

(function () {
    'use strict';

    bindhq.ns('forms');

    bindhq.forms.submit = function () {
        $(this).closest('form').submit();
    };

    /**
     * @param {jQuery} container
     */
    bindhq.forms.initModalContainer = function (container) {
        if (container.hasClass('form-files-edit')) {
            bindhq.forms.file.initContainer(container);
        }

        if (
            container.hasClass('form-history-edit') ||
            container.hasClass('form-history-add')
        ) {
            bindhq.history.initModal(container);
        }

        if (container.hasClass('form-new-abacus-journal')) {
            bindhq.abacus.bankReconciliation.initJournalModal(container);
        }

        if (container.hasClass('form-modal-ajax')) {
            container.modalAjax();
        }
    };

    /**
     *
     */
    bindhq.forms.clearForm = function () {
        $(this)
            .closest('form')
            .find(':input')
            .each(function () {
                switch (this.type) {
                    case 'password':
                    case 'text':
                    case 'textarea':
                        $(this).val('');
                        break;
                    case 'checkbox':
                    case 'radio':
                        $(this).prop('checked', false);
                }
            });
    };

    /**
     * Initialise the specified container, where the context is
     * the container (doesnt have to be the form itself)
     *
     */
    bindhq.forms.initContainer = function (container) {
        $('.wysiwyg', container).initEach(bindhq.wysiwyg.initContainer);

        $('[data-collection-prefix]', container).initEach(
            bindhq.forms.collection.inline.initContainer,
        );

        $('[data-collection-prefix="items"]', container).initEach(
            bindhq.productionReport.initContainer,
        );

        $('.file-inputs[data-prototype]', container).initEach(
            bindhq.forms.files.initAddFile,
        );

        $('.file-inputs-tags[data-prototype]', container).initEach(
            bindhq.forms.files.initAddTaggableFile,
        );

        $('.datepicker', container).each(bindhq.forms.datepicker.init);

        $('.money', container).each(bindhq.forms.mask.init);

        $('.clear-filter', container).each(bindhq.forms.filter.init);

        $('.ajax-autocomplete', container).each(
            bindhq.forms.ajaxautocomplete.init,
        );

        $('.multi-select', container).each(bindhq.forms.multiselect.init);

        $('.submit-on-change', container).on(
            'change click',
            bindhq.forms.submit,
        );

        $('[data-auto-submit-form]', container).on('blur', bindhq.forms.submit);

        $('select.select2', container).each(bindhq.forms.select.init);

        $('.select2-free-text', container).each(
            bindhq.forms.select.initFreeText,
        );

        $('.address_lookup', container).each(bindhq.forms.address.init);

        $('.address-lookup', container).initEach(
            bindhq.forms.addresslookup.init,
        );

        $('.sections', container).each(bindhq.forms.sections.initSections);

        $('[data-formpicker=yes]', container).each(
            bindhq.forms.formpicker.init,
        );

        $('.application-insured', container).each(
            bindhq.applications.insured.init,
        );

        $('.section', container).each(bindhq.forms.toggleable.init);

        $('.inactivable-with-comment', container).initEach(
            bindhq.forms.inactivableComment.init,
        );

        $('.btn-clear', container).click(bindhq.forms.clearForm);

        $(container).on('click', '.inputs .file .delete', function (e) {
            e.preventDefault();
            $(e.currentTarget).closest('.file').remove();
        });

        $(container).on(
            'keyup',
            '.input-append input.email, .input-prepend input.email',
            bindhq.forms.email.onKeyup,
        );

        if (container.hasClass('form-quote-template')) {
            bindhq.forms.programs.init.apply(container);
        }

        if (container.hasClass('form-quote')) {
            bindhq.forms.quotes.init.apply(container);
        }

        if (container.hasClass('form-rates')) {
            bindhq.forms.rates.init.apply(container);
        }

        if (container.hasClass('form-endorsements')) {
            bindhq.endorsements.init.apply(container);
        }

        if (container.hasClass('form-policy')) {
            bindhq.forms.policy.initContainer(container);
        }

        if (container.hasClass('form-policy-rebook')) {
            bindhq.forms.policy.initRebookContainer(container);
        }

        if (container.hasClass('form-binding')) {
            bindhq.forms.binding.init.apply(container);
        }

        if (container.hasClass('form-history-by-type')) {
            bindhq.forms.historyByType.initContainer(container);
        }

        if (container.hasClass('form-task')) {
            bindhq.forms.tasks.initTask(container);
        }

        if (container.hasClass('form-user-post')) {
            bindhq.forms.userPost.initContainer(container);
        }

        if (container.hasClass('form-lesson')) {
            bindhq.forms.lesson.initContainer(container);
        }

        if (container.hasClass('form-user-post-comment')) {
            bindhq.forms.userPostComment.initContainer(container);
        }

        if (container.hasClass('agent-activity')) {
            bindhq.forms.activity.init(container);
        }

        if (container.hasClass('form-user')) {
            bindhq.forms.user.initContainer(container);
        }

        if (container.hasClass('form-agent')) {
            bindhq.forms.agent.initContainer(container);
        }

        if (container.hasClass('form-renewals-manager')) {
            bindhq.renewals.manager.initContainer(container);
        }

        if (container.hasClass('form-itc-noc')) {
            bindhq.itcnoc.init.apply(container);
        }

        if (container.hasClass('form-file-delete')) {
            bindhq.forms.fileDelete.initContainer(container);
        }

        if (container.hasClass('form-insured')) {
            bindhq.forms.insured.initContainer(container);
        }

        if (container.hasClass('form-production-report')) {
            bindhq.productionReport.init(container);
        }

        if (
            container.hasClass('collection-item') &&
            container.children().hasClass('group')
        ) {
            bindhq.productionReport.initContainer(container);
        }

        if (
            container.hasClass('form-invoice-payment') ||
            container.hasClass('form-invoice-batch')
        ) {
            bindhq.forms.invoices.initContainer(container);
        }

        if (
            container.hasClass('form-new-abacus-payment') ||
            container.hasClass('form-edit-abacus-payment')
        ) {
            bindhq.abacus.payment.initContainer(container);
        }

        if (container.hasClass('form-new-tax-reconciliation')) {
            bindhq.abacus.taxReconciliation.initContainer(container);
        }

        if (container.hasClass('form-abacus-deposit-new')) {
            bindhq.abacus.deposit.initContainer(container);
        }

        if (container.hasClass('form-show-abacus-payment')) {
            bindhq.abacus.paymentShow.initContainer(container);
        }

        if (container.hasClass('form-new-abacus-journal')) {
            bindhq.abacus.journal.initContainer(container);
        }

        if (container.hasClass('form-new-abacus-payment-modal')) {
            bindhq.abacus.paymentModal.initContainer(container);
        }

        if (container.hasClass('form-new-abacus-bill')) {
            bindhq.abacus.newTransaction.initContainer(container);
        }

        if (container.hasClass('form-abacus-cheque-batch-new')) {
            bindhq.abacus.chequeBatch.initContainer(container);
        }

        if (container.hasClass('abacus-transactions-filter-form')) {
            bindhq.abacus.transactionListFilter.initContainer(container);
        }

        if (container.hasClass('form-receipt-disbursement-configuration')) {
            bindhq.abacus.receiptDisbursementConfiguration.initContainer(
                container,
            );
        }

        $('.abacus-customer-select', container).initEach(
            bindhq.abacus.customerSelect.initContainer,
        );

        const modal = container.closest('.modal');

        if (modal.length > 0) {
            bindhq.forms.initModalContainer(container);
        }

        if (container.attr('data-js-hook') === 'sla-batch-edit') {
            bindhq.forms.slaBatch.initContainer(container);
        }

        if (container.attr('data-js-itcNoc-report-form') !== undefined) {
            bindhq.forms.itcNocReport.initContainer(container);
        }

        if (container.attr('data-js-application-form') !== undefined) {
            bindhq.forms.applicationIndexForm.initContainer(container);
        }

        container.find('.select2-input[autocomplete="off"]').each(function () {
            $(this).attr('autocomplete', 'new-password');
        });

        // Set the validator onto the container so the container can hook
        // into the validator for custom validation
        container.validator = container.validate({
            normalizer: function (value) {
                return typeof value === 'string' ? value.trim() : value;
            },
            ignoreTitle: true,
            focusInvalid: false,
            ignore: ':hidden:not(.wysiwyg)',
            errorElement: 'span',
            errorPlacement: FormValidator.errorPlacement,
            highlight: FormValidator.highlight,
            unhighlight: FormValidator.unhighlight,
            invalidHandler: FormValidator.invalidHandler,
        });
    };

    bindhq.forms.init = function () {
        const container = $(this);

        bindhq.forms.initContainer(container);
    };
})();
