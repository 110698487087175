const {
    Animation,
} = require('../../../../../../../assets/js/BindHQ/Utils/Animation');

const { Affix } = require('../../../../../../../assets/js/BindHQ/Utils/Affix');

(function () {
    'use strict';

    /**
     * Initialize a container with all of the functionality for the site.
     *
     * @param {jQuery} container
     */
    bindhq.initContainer = function (container) {
        const defaultMapCoords = [51.480198, -3.181929];
        const defaultMapZoom = 14;

        $('[data-js-stop-propagation]').click(function (evt) {
            evt.stopPropagation();
        });

        $('.box-checklist', container).initEach(bindhq.checklist.initContainer);

        $('.search-container:visible', container).initEach(
            bindhq.search.initContainer,
        );

        $('.report', container).each(bindhq.reports.init);

        $('[data-swal-text]', container).initEach(bindhq.sweetAlert.init);

        $('.nav-tabs-dynamic', container).each(bindhq.tabs.init);

        $('.file-attach[data-owners]', container).initEach(
            bindhq.forms.files.initAttachFile,
        );

        $('[data-toggle="modal"]', container).each(bindhq.modalAjax.init);

        $('[data-intabajax]', container).each(bindhq.intabAjax.init);

        $('form, .form', container)
            .filter(':not(.no-auto-init)')
            .each(bindhq.forms.init);

        $('form.application', container).initEach(
            bindhq.forms.application.initContainer,
        );

        $('form.agency', container).each(bindhq.forms.agency.init);

        $('form .multi-address', container).each(
            bindhq.forms.multiAddress.init,
        );

        $('form.agencies', container).each(bindhq.agencies.init);

        $('.gmaps-container', container).each(function () {
            bindhq.gmaps.init.apply(this, [defaultMapCoords, defaultMapZoom]);
        });

        $('.appetite-carriers', container).each(
            bindhq.applications.carriers.init,
        );

        $('.quotes-tab', container).each(bindhq.forms.quotes.initLinksToQuotes);

        $('.fileviewer-select', container).each(bindhq.fileviewer.init);

        $('.history', container).each(bindhq.history.init);

        $('.pagination', container).each(bindhq.pagination.init);

        $('.iscroll', container).each(bindhq.iscroll.init);

        $('.iscroll.tablesorter', container).each(
            bindhq.iscrollTableSorter.init,
        );

        $('.policy-balance', container).each(bindhq.policies.balance.init);

        $('.timed-fade-out', container).fadeAndRemoveTimed(3000);

        $('[data-js-hook="formset-viewer"]', container).initEach(
            bindhq.policies.formSet.initContainer,
        );

        $('[data-js-hook="conversations"]', container).initEach(
            bindhq.conversations.initContainer,
        );

        $('[data-js-hook="application-underwriting"]', container).initEach(
            bindhq.applications.underwriting.initContainer,
        );

        $('[data-js-hook="inspection"]', container).initEach(
            bindhq.forms.inspection.initContainer,
        );

        $('.carrier-policy-numbers', container).each(
            bindhq.carriers.policyNumbers.init,
        );

        $('.policy-overview', container).each(bindhq.policy.overview.init);

        $('.search-offline', container).initEach(
            bindhq.offlineSearch.initContainer,
        );

        $('.scroller', container).initEach(bindhq.scroller.init);

        $('.policyfilter', container).initEach(
            bindhq.policyfilter.filter.initContainer,
        );

        $('.policyfilter-policy', container).initEach(
            bindhq.policyfilter.filter.initPolicy,
        );

        $('.map', container).initEach(bindhq.maps.init);

        $('.social', container).initEach(bindhq.social.initContainer);

        $('.steps', container).initEach(bindhq.steps.initContainer);

        $('.modal-help', container).initEach(bindhq.help.initModal);

        $('.quote-status', container).initEach(
            bindhq.quoteStatus.initContainer,
        );

        $('.table-tasks', container).initEach(bindhq.forms.tasks.initContainer);

        $('.tbodysorter', container).initEach(bindhq.tbodysorter.initContainer);

        $('.table-horizontal-scroll', container).initEach(
            bindhq.table.horizontalScroll.init,
        );

        $('.control-section-select', container).initEach(
            bindhq.controlSection.initSelector,
        );

        $('.quiz', container).initEach(bindhq.quiz.init);

        $('.all-settings', container).initEach(bindhq.settings.initContainer);

        $('.general-settings', container).initEach(
            bindhq.generalSettings.initContainer,
        );

        $('.training', container).initEach(bindhq.training.init);

        $('.table-customer-receivables', container).initEach(
            bindhq.abacus.customerReceivables.init,
        );

        $('.lesson-show', container).initEach(bindhq.lesson.init);

        $('.abacus-receipt-show, .abacus-payment-show').initEach(
            bindhq.abacus.paymentShow.initShow,
        );

        $('.abacus-void-show, .abacus-offset-show').initEach(
            bindhq.abacus.appliesShow.initShow,
        );

        $('.abacus-payment-context-selector', container).initEach(
            bindhq.abacus.contextSelector.init,
        );

        $('.abacus-transaction-table', container).initEach(
            bindhq.abacus.transactionFilter.initContainer,
        );

        $('.report-table-csv', container).initEach(
            bindhq.reportTableCsv.initContainer,
        );

        $('.abacus-audit-report-table', container).initEach(
            bindhq.abacus.audit.initContainer,
        );

        $('.transaction-group-table', container).initEach(
            bindhq.policyAccountingFilters.initContainer,
        );

        $('[data-js-monthly-production-chart]', container).initEach(
            bindhq.reports.productionMonthly.chart.init,
        );

        $('.dashboard', container).initEach(bindhq.dashboard.initContainer);

        $('.menu-toggle', container).initEach(bindhq.menuToggle.init);

        $('[data-js-hook="table-listing"]', container).each(
            bindhq.tableListing.init,
        );

        $('.table-listing', container).each(bindhq.tableListing.initDisplay);

        $('.progress-circle', container).initEach(bindhq.progressBars.init);

        $('.table-chart', container).initEach(bindhq.charts.init);

        $('.email-template-form', container).initEach(
            bindhq.emailTemplates.init,
        );

        $('.markup-raw', container).initEach(bindhq.markup.initContainer);

        $('.tablesorter').tablesorter({
            textExtraction: bindhq.util.tableSortText,
        });

        $('input.custom-file-input[type="file"]').change(function (e) {
            if (1 !== e.target.files.length) {
                return;
            }

            const fileName = e.target.files[0].name;

            $('.custom-file-label[for=' + e.target.id + ']').html(fileName);
        });

        const animation = new Animation($(window));
        $(container).on('click', 'a.scroll-to-anchor', function (e) {
            const href = $(this).attr('href');
            if (href && href.startsWith('#')) {
                e.preventDefault();
                const selector = $(e.target).attr('href');
                const offset = $(this).data('offset') || 120;

                if ($(selector).length) {
                    animation.scrollTo($(selector).get(0), offset);
                }
            }
        });

        const affix = new Affix(container);
        affix.init();

        $('.btn-group-tab', container).initEach(bindhq.tabs.initGroupTab);

        bindhq.dnd.initContainer(container);

        if (bindhq.isEmailGranted()) {
            bindhq.mailpane.initContainer(container);

            $('a[href^="mailto:"]', container).each(bindhq.mailto.init);
        }

        if (bindhq.reports && bindhq.reports.initContainer) {
            bindhq.reports.initContainer(container);
        }
    };

    bindhq.init = function () {
        const container = $(this);

        bindhq.initContainer(container);
    };

    /**
     * @param {String} role
     *
     * @return {boolean}
     */
    bindhq.isGranted = function (role) {
        const roleString = $('body').data('userroles') || '';
        const roles = roleString.split(',');

        for (let i = 0; i < roles.length; i++) {
            if (roles[i] === role) {
                return true;
            }
        }

        return false;
    };

    /**
     * @return {String}
     */
    bindhq.releaseVersion = function () {
        return $('body').data('release-version') || 'dev';
    };

    /**
     * @return {boolean}
     */
    bindhq.isEmailGranted = function () {
        return $('body').data('userEmailIntegration') === true;
    };
})();
