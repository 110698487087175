(function () {
    'use strict';

    bindhq.nsIn('forms.insured', {
        onCopyChange: function (container) {
            const copy = $('.copy', container);
            const mailAddress = $('.mailing-address-section', container);
            if ($(copy).prop('checked')) {
                $(mailAddress).hide();
            } else {
                $(mailAddress).show();
            }
        },

        /**
         * Dynamically changes the form fields that are displayed depending on the
         * type of insured selected.
         */
        onInsuredTypeChange: function (container) {
            const insuredType = $('#insured_type', container);

            if (
                insuredType.val() === container.data('insured-type-individual')
            ) {
                $('.non-individual', container).hide();
                $('.individual', container).show();
            } else {
                $('.non-individual', container).show();
                $('.individual', container).hide();
            }
        },

        initContainer: function (container) {
            this.onCopyChange(container);

            const onCopyChange = _.partial(this.onCopyChange, container);

            container.on('change', '.copy', onCopyChange);

            const onInsuredTypeChange = _.partial(
                this.onInsuredTypeChange,
                container,
            );

            $('#insured_type').change(onInsuredTypeChange);

            // Initialise the displayed fields to whatever the default type of insured is
            onInsuredTypeChange();
        },
    });
})();
