(function () {
    'use strict';

    bindhq.ns('applications.insured');

    /**
     * @param {jQuery} container
     * @param {Object} insured
     */
    bindhq.applications.insured.onSuccess = function (container, insured) {
        const evt = $.Event('insured_created', { insured: insured });

        container.trigger(evt);
    };

    /**
     * @param {jQuery} container
     * @param {jQuery} modal
     * @param {jQuery.Event} evt
     */
    bindhq.applications.insured.onError = function (container, modal, evt) {
        modal.modal('hide');

        const onSuccess = _.partial(
            bindhq.applications.insured.onSuccess,
            container,
        );
        const config = {
            success: onSuccess,
        };

        const clearing = $(evt.responseText)
            .modal('show')
            .find('form')
            .modalAjax(config)
            .closest('.modal');

        $('[data-insured-id]', clearing).each(function () {
            const insured = {
                id: $(this).data('insured-id'),
                name: $(this).data('insured-name'),
            };

            const onClick = function () {
                clearing.modal('hide');
                onSuccess(insured);
            };

            const button = $('<a></a>')
                .addClass('btn btn-primary btn-small')
                .text('Select')
                .click(onClick);

            $('.title .content', this).prepend(button);
        });
    };

    /**
     *
     */
    bindhq.applications.insured.onModalShown = function (
        container,
        modal,
        evt,
    ) {
        const config = {
            success: _.partial(
                bindhq.applications.insured.onSuccess,
                container,
            ),
            error: _.partial(
                bindhq.applications.insured.onError,
                container,
                modal,
            ),
        };

        $('form', modal).modalAjax(config);
    };

    /**
     * @param {Function} onShown
     * @param {jQuery} modal
     */
    bindhq.applications.insured.initModal = function (onShown, modal) {
        modal.on('shown', _.partial(onShown, modal));
    };

    /**
     * @param {jQuery} container
     * @param {jQuery.Event} evt
     */
    bindhq.applications.insured.onInsuredCreated = function (container, evt) {
        $('input.insured', container)
            .select2('data', evt.insured)
            .trigger('change');
    };

    /**
     * @param {jQuery} container
     * @param {Object} insured
     */
    bindhq.applications.insured.updateAddress = function (container, insured) {
        const fields = [
            'zip',
            'doing_business_as',
            'address_line1',
            'address_line2',
            'address_line3',
        ];

        _.each(fields, function (field) {
            $('.insured-address .' + field, container)
                .val(insured[field] || '')
                .trigger('change');
        });
    };

    /**
     * Formatting selection also involves updating address fields
     *
     * @param {jQuery} container
     * @param {Object} insured
     *
     * @return {String}
     */
    bindhq.applications.insured.formatSelection = function (
        container,
        insured,
    ) {
        bindhq.applications.insured.hasChanged(container, insured);

        return insured.name || '(no name)';
    };

    /**
     * If we have an insured update the link to not create a new one
     *
     * @param {jQuery} container
     * @param {Object} insured
     */
    bindhq.applications.insured.updateInsuredLink = function (
        container,
        insured,
    ) {
        if (insured.id) {
            $('.new-insured', container)
                .unbind('click')
                .attr({
                    href: '/secure/admin/insureds/' + insured.id,
                    target: '_blank',
                })
                .html('View');
        } else {
            const button = $('.new-insured', container)
                .unbind('click')
                .attr({
                    href: '/secure/admin/insureds/new',
                })
                .html('Create');

            bindhq.applications.insured.initCreateNew(container, button);
        }
    };

    /**
     * Select2 fired change event handler
     *
     * @param {jQuery} container
     * @param {jQuery.Event} evt
     */
    bindhq.applications.insured.onChange = function (container, evt) {
        const insured = $(evt.currentTarget).select2('data');

        bindhq.applications.insured.hasChanged(container, insured);
    };

    /**
     * @param {jQuery} container
     * @param {Object} insured
     */
    bindhq.applications.insured.hasChanged = function (container, insured) {
        bindhq.applications.insured.updateAddress(container, insured);

        if ($('.new-insured', container).hasClass('view-insured')) {
            bindhq.applications.insured.updateInsuredLink(container, insured);
        }
    };

    /**
     * @param {jQuery} container
     * @param {Object} select
     */
    bindhq.applications.insured.onClearClick = function (container, select) {
        select.select2('val', '');
        bindhq.applications.insured.hasChanged(container, {});

        return false;
    };

    /**
     * @param {jQuery} container
     * @param {jQuery} button
     */
    bindhq.applications.insured.initCreateNew = function (container, button) {
        const initModal = _.partial(
            bindhq.applications.insured.initModal,
            _.partial(bindhq.applications.insured.onModalShown, container),
        );

        bindhq.modalAjax.initContainer(button, { callback: initModal });
    };

    /**
     * @param {jQuery} container
     */
    bindhq.applications.insured.initContainer = function (container) {
        const self = bindhq.applications.insured;
        const newButton = $('.new-insured', container);
        const select = $('input.insured', container);
        const clearButton = $('.clear-insured', container);

        clearButton.click(_.partial(self.onClearClick, container, select));

        const ajaxConfig = {
            formatSelection: _.partial(self.formatSelection, container),
        };

        container.on(
            'insured_created',
            _.partial(self.onInsuredCreated, container),
        );

        self.initCreateNew(container, newButton);

        bindhq.forms.ajaxselect.initContainer(select, ajaxConfig);
    };

    /**
     * Initialise an insured selector by context
     */
    bindhq.applications.insured.init = function () {
        const container = $(this);

        bindhq.applications.insured.initContainer(container);
    };
})();
