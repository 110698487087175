(function () {
    'use strict';

    bindhq.ns('forms.binding');

    bindhq.forms.binding.programmeButtonTpl =
        '<div class="btn-group btn-group-programmes">' +
        '<a class="btn dropdown-toggle" data-toggle="dropdown">' +
        ' Assign for programme ' +
        '<span class="caret"></span>' +
        '</a>' +
        '<ul class="dropdown-menu">' +
        '<% _.each(programmes, function(programme) { %>' +
        '<li>' +
        '<a href="#" data-programme="<%= JSON.stringify(programme).replace(/"/g, "&quot;") %>"><%= programme.name %></a>' +
        '</li>' +
        '<% }); %>' +
        '</ul>' +
        '</div>';

    bindhq.forms.binding.noProgrammesTpl =
        '<div class="alert alert-error no-programmes">No policy numbers available</div>';

    /**
     * @param {jQuery} container
     * @param {jQuery.Event} evt
     */
    bindhq.forms.binding.onConfirmClick = function (container, evt) {
        $(evt.currentTarget).progress('start');

        $('.progress', container).attr('disabled', 'disabled');

        $('form', container).submit().attr('disabled', 'disabled');

        return false;
    };

    /**
     * @param {jQuery} container
     * @param {jQuery} files
     * @param {String} html
     */
    bindhq.forms.binding.onConfirmLoad = function (container, files, html) {
        const onConfirmClick = _.partial(
            bindhq.forms.binding.onConfirmClick,
            container,
        );

        container.empty().html(html).find('.hidden').append(files);

        container.on('click', '[type=submit]', onConfirmClick);
    };

    bindhq.forms.binding.onError = function (container, submit, jqXHR) {
        let message = 'Error';
        let data = {};

        bindhq.modalAjax.helper.unlockForm(container, submit);
        submit.progress('stop');

        try {
            data = $.parseJSON(jqXHR.responseText);
            message = data.message;
        } catch (err) {
            // message set above
        }

        bindhq.loader.hide();
        bindhq.intabAjax.showAlert('error', container, message);

        $('.binding-blockers').html('');
    };

    bindhq.forms.binding.onInspectionChanged = function () {
        const select = $(this);
        const data = select.select2('data');

        select.next('textarea').val(data ? data.email_template : '');
    };

    bindhq.forms.binding.onSl2ContactChanged = function () {
        const select = $(this);
        const data = select.select2('data');

        select.parents('tr').find('.phone-number').html(data.phone_extension);
    };

    bindhq.forms.binding.messageAddedToTextarea = function (textarea, message) {
        const value = textarea.val() || '';
        const checkTextareaContainMessage = value.indexOf(message);

        return checkTextareaContainMessage !== -1;
    };

    bindhq.forms.binding.onSl2ReasonSelected = function () {
        const select = $(this);
        const data = select.select2('data');
        const message = data.name;
        const textarea = select.parents('.sl2').find('textarea');
        const messageAddedToTextarea =
            bindhq.forms.binding.messageAddedToTextarea(textarea, message);

        if (!messageAddedToTextarea) {
            textarea.appendVal(message);
        }
    };

    bindhq.forms.binding.renderSl1Prototype = function (form, field, prefix) {
        let prototype = field.data('prototype');
        const fields = {
            carrier: 'carrier',
            policy_num: 'policyNum',
            premium: 'premium',
        };

        _.each(fields, function (fieldName, strName) {
            const re = new RegExp(
                '(id="' + prefix + '__name___' + strName + '")',
            );
            const value = $('#binding_' + fieldName, form).val();

            prototype = prototype.replace(re, '$1 value="' + value + '" ');
        });

        field.attr('data-prototype', prototype).data('prototype', prototype);
    };

    bindhq.forms.binding.updateSl1Prototype = function (form) {
        const prefix = 'binding_sl_one_sl_one_';
        const field = $('#' + prefix + 'control_group', form);

        if (field.length > 0) {
            bindhq.forms.binding.renderSl1Prototype(form, field, prefix);
        }
    };

    /**
     * Dynamically set the required attribute of textarea and the default value
     * of the dropdown
     *
     */
    bindhq.forms.binding.inspectionChecked = function (
        checkbox,
        companySelect,
        textarea,
    ) {
        if (checkbox.is(':checked')) {
            companySelect.select2('val', '1', true);
            textarea.attr('required', 'required');
        } else {
            textarea.removeAttr('required');
        }
    };

    /**
     * Updates the inspection company fields
     *
     */
    bindhq.forms.binding.inspectionSelection = function (textarea, company) {
        if (company.email_template) {
            textarea.val(company.email_template);
        }

        return company.name || '(no company)';
    };

    /**
     * @param {jQuery} form
     * @param {Object} policyNumber
     */
    bindhq.forms.binding.onPolicyNumberLoaded = function (form, policyNumber) {
        const selector = '.policy-num, .slone-policy-num';
        const inputs = $(selector, form).val('');

        if (policyNumber) {
            inputs.val(policyNumber.policy_num);
        }
    };

    /**
     * A programme has been clicked, init autofill for policynum
     *
     * @param {jQuery.Event} evt
     */
    bindhq.forms.binding.onProgrammeClicked = function (evt) {
        const anchor = $(evt.currentTarget);
        const programme = anchor.data('programme');
        const url =
            '/secure/admin/carriers/' +
            programme.carrier.id +
            '/programmes/' +
            programme.id +
            '/policyNumbers/assign.json'; // @TODO
        const form = anchor.parents('form');
        const onLoad = _.partial(
            bindhq.forms.binding.onPolicyNumberLoaded,
            form,
        );

        $('.policy-num', form).loadContent({
            url: url,
            success: onLoad,
        });
    };

    bindhq.forms.binding.initProgrammes = function (input, programmes) {
        const self = bindhq.forms.binding;
        const html = bindhq.util.template(self.programmeButtonTpl, {
            programmes: programmes,
        });
        const onClick = bindhq.util.noDefault(self.onProgrammeClicked);

        $(html).insertAfter(input).find('.dropdown-menu a').click(onClick);
    };

    bindhq.forms.binding.initNoProgrammes = function (input) {
        const html = bindhq.util.template(
            bindhq.forms.binding.noProgrammesTpl,
            {},
        );

        $(html).insertAfter(input);
    };

    /**
     * Initialise auto-assignment of policy numbers
     */
    bindhq.forms.binding.initPolicyNum = function () {
        const self = bindhq.forms.binding;
        const input = $(this);
        const programmes = input.data('programmes');

        if (programmes.length > 0) {
            self.initProgrammes(input, programmes);
        } else {
            self.initNoProgrammes(input);
        }
    };

    /**
     *
     */
    bindhq.forms.binding.init = function () {
        const self = bindhq.forms.binding;
        const form = $(this);

        bindhq.forms.quotes.initTermHelpers(form);

        const updateSl1Prototype = _.partial(self.updateSl1Prototype, form);
        const companySelect = form.find('#binding_inspectionCompany');
        const checkbox = form.find('.inspection-checkbox');
        const textarea = form.find('.inspection-textarea');
        const validateFields = _.partial(
            self.inspectionChecked,
            checkbox,
            companySelect,
            textarea,
        );
        checkbox.on('change', validateFields);

        $('.sl2 .datepicker .hasDatePicker', form).datepicker(
            'setDate',
            new Date(),
        );

        $('.policy-num', form).each(bindhq.forms.binding.initPolicyNum);

        $('input.sl2-contact', form).on('change', self.onSl2ContactChanged);

        $('.sl2-diligence', form).on('change', self.onSl2ReasonSelected);

        $('#binding_policyNum, #binding_premium, #binding_carrier', form).on(
            'change',
            _.debounce(updateSl1Prototype, 500),
        );

        form.formAjax({
            success: _.partial(
                bindhq.forms.binding.onConfirmLoad,
                form.parent(),
                $('input[type=file]', form).remove(),
            ),
        });

        updateSl1Prototype();
    };
})();
