(function () {
    'use strict';

    bindhq.ns('fileviewer');

    bindhq.fileviewer.showFile = function (fileId, name) {
        const viewer = $('#fileviewer-display');
        const clean = name.replace('/', '-');
        const url = '/secure/files/' + fileId + '/' + encodeURIComponent(clean);
        const iframe = $('<iframe></iframe>').attr({ src: url });

        viewer.empty().append(iframe);
    };

    bindhq.fileviewer.onSelect = function (select) {
        const file = select.select2('data');

        if (file) {
            bindhq.fileviewer.showFile(file.id, file.text);
        }
    };

    bindhq.fileviewer.init = function () {
        const select = $(this);
        const onSelect = _.partial(bindhq.fileviewer.onSelect, select);

        select.on('change', onSelect);

        onSelect();
    };
})();
