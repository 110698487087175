// CSS files
import 'tom-select/dist/css/tom-select.bootstrap4.css';
require('select2/select2.css');
require('@fortawesome/fontawesome-free/css/all.css');
require('bootstrap/dist/js/bootstrap');
require('bootstrap/dist/css/bootstrap.css');
require('./styles/dropzone.css');

require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/utilities/variables.css');

require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/bootstrap-override.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/css/jquery-ui-bootstrap-1.8.16.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/css/redactor-926.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/css/sweetalert.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/bootstrap-responsive.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/forms.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/buttons.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/tabs.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/tables.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/search.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/panels.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/navigation.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/boxes.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/alerts.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/modals.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/listing.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/stats.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/steps.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/item-actions.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/files.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/mailpane.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/sidebars.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/invoices.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/application.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/brokerage-submissions.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/quote.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/policy.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/users.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/insured.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/history.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/binding.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/endorsements.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/user-groups.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/social.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/training.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/reports.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/widgets.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/splash.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/login.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/conversations.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/main.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/premium-finance.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/underwriting.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/settings.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/confirm.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/nanobar.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/program/contractors-guard.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/css/overrides/tom-select.css');
require('../src/BindHQ/Bundle/DashboardBundle/Resources/public/css/dashboard.css');
require('../src/BindHQ/Bundle/RenewalsBundle/Resources/public/css/main.css');
require('../src/BindHQ/Bundle/PolicyTransferBundle/Resources/public/css/main.css');
require('../src/BindHQ/Bundle/MapsBundle/Resources/public/css/main.css');
require('../src/BindHQ/Bundle/EditorBundle/Resources/public/css/main.css');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/css/codemirror-5.5.css');
require('../src/BindHQ/Bundle/TemplateEditorBundle/Resources/public/css/main.css');
require('../src/BindHQ/Bundle/TaxBundle/Resources/public/css/main.css');
require('../src/BindHQ/Bundle/ImportBundle/Resources/public/css/main.css');

// 3rd Party
require('@fortawesome/fontawesome-free/js/all.js');
require('select2/select2.js');
require('jquery-validation');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/jquery-ui-1.10.3.custom.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/jquery.autoresize-1.04.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/jquery.tablesorter.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/jquery.tablesorter.widgets.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/jquery.hashchange-1.3.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/lodash-2.4.1.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/underscore.strings-0.1.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/jquery.form-3.18.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/jquery.cookie-1.4.1.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/redactor-926.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/sweetalert-dev.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/3rdparty/js/md5.js');

// Extensions
require('./js/LibraryCustomisations/jquery-customisations.js');
require('./js/LibraryCustomisations/lodash-customisations.js');

// Core
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/bindhq.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/common.js');

// Javascript Templates
require('./js/templates/files_tag.js');
require('./js/templates/loader_pulser.js');
require('./js/templates/mailpane_files.js');
require('./js/templates/mailpane_message_failed.js');
require('./js/templates/search_detail.js');
require('./js/templates/search_results.js');
require('./js/templates/transaction_table_pagination.js');
require('./js/templates/item_actions.js');
require('./js/templates/mailpane_file.js');
require('./js/templates/mailpane_message.js');
require('./js/templates/mailpane_messages.js');
require('./js/templates/search_result.js');
require('./js/templates/search_suggestions.js');
require('./js/templates/brokerage_marketing_company.js');
require('./js/templates/brokerage_marketing_company_contact.js');
require('./js/templates/drag_files_hint.js');
require('./js/templates/table_current_filter.js');
require('./js/templates/table_pagination_numerated.js');
require('./js/templates/validation_error.js');
require('./js/templates/renewals_manager_file.js');
require('./js/templates/dropzone_file.js');

// Bundle
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/shims.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/main.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/util.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/csv.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/validator.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/files.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/ga.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/divisionSwitcher.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/menuToggle.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/dnd.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/markup.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/tabs.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/tasks.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/loader.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/checklist.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/alerts.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/sweetAlert.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/controlSection.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/ajax.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/reportTableCsv.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/mailpane.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/agencies.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/carrierPrograms.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/subjectivities.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/activity.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/fileDelete.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/sections.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/policy.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/toggleable.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/formpicker.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/ajaxselect.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/multiselect.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/file.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/files.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/filter.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/inspection.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/binding.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/datepicker.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/applicationIndexForm.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/select.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/application.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/agency.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/ajaxautocomplete.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/email.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/quotes.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/programs.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/historyByType.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/endorsements.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/rates.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/slaBatch.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/tasks.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/user.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/agent.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/lesson.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/userPost.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/userPostComment.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/invoices.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/insured.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/itcNocReport.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/mask.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/multiAddress.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/collection/inline.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/applications/carriers.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/applications/documents.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/applications/insured.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/applications/underwriting.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/carriers/policyNumbers.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/policies/balance.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/policies/formSet.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/fileviewer.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/emailTemplates.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/quoteStatus.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/gmaps-custom.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/gmaps.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/address.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/address-lookup.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/itc-noc.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/send-email.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/modal-ajax.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/intab-ajax.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/history.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/social.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/search/parser.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/search.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/tableListing.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/reports.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/reports/utils.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/reports/policyAudit.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/reports/agencyRanking.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/reports/productionMonthly.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/reports/issuance.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/reports/businessPlans.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/reports/productionOverview.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/offlineSearch.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/pagination.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/iscroll.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/iscrollTableSorter.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/policies/overview.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/mailto.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/scroller.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/progress.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/tbodysorter.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/help.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/steps.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/itemActions.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/wysiwyg.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/animator.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/social.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/conversations.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/productionReport.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/horizontalScroll.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/quiz.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/training.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/lesson.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/policyAccountingFilters.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/settings.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/generalSettings.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/localStorage.js');
require('../src/BindHQ/Bundle/DashboardBundle/Resources/public/js/charts.js');
require('../src/BindHQ/Bundle/DashboardBundle/Resources/public/js/progress.js');
require('../src/BindHQ/Bundle/DashboardBundle/Resources/public/js/dashboard.js');
require('../src/BindHQ/Bundle/RenewalsBundle/Resources/public/js/manager.js');
require('../src/BindHQ/Bundle/RenewalsBundle/Resources/public/js/overview.js');
require('../src/BindHQ/Bundle/PolicyTransferBundle/Resources/public/js/filter.js');
require('../src/BindHQ/Bundle/MapsBundle/Resources/public/js/main.js');
require('../src/BindHQ/Bundle/MapsBundle/Resources/public/js/byArea.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/payment.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/taxReconciliation.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/transactionMultiSelect.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/achBatchChangeset.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/achBatch.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/deposit.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/customerSelect.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/paymentCsv.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/paymentShow.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/appliesShow.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/journal.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/paymentModal.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/transactionFilter.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/contextSelector.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/newTransaction.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/transactionLoading.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/transactionTable.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/transactionListFilter.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/chequeBatch.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/customerReceivables.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/audit.js');
require('../src/BindHQ/Bundle/AbacusBundle/Resources/public/js/receiptDisbursementConfiguration.js');
require('../src/BindHQ/Bundle/WebAppBundle/Resources/public/js/forms/inactivableComment.js');

import './bootstrap';

global.$ = global.jQuery = $;

require('luxon').Settings.throwOnInvalid = true;
