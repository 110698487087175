(function () {
    'use strict';

    bindhq.nsIn('sweetAlert', {
        displayMessage: function (container) {
            const type = container.data('swal-type')
                ? container.data('swal-type')
                : 'info';

            const title = container.data('swal-title')
                ? container.data('swal-title')
                : '';

            const text = container.data('swal-text')
                ? container.data('swal-text')
                : '';

            swal({
                title: title,
                text: text,
                type: type,
            });
        },

        init: function (container) {
            const onClick = _.partial(this.displayMessage, container);
            $(container).on('click', onClick);
        },
    });
})();
