(function () {
    'use strict';

    bindhq.nsIn('applications.underwriting', {
        /**
         * @param {jQuery} container
         *
         * @return {google.maps.LatLng}
         */
        originalMapPos: function (container) {
            const div = $('.map', container);
            const lat = div.data('map-latitude') || 33.7585739;
            const lng = div.data('map-longitude') || -117.821202;

            return new google.maps.LatLng(lat, lng);
        },

        /**
         * @param {jQuery} container
         * @param {google.maps.Map} map
         */
        showStreetView: function (container, map) {
            const sv = map.getStreetView();
            const pos = this.originalMapPos(container);

            sv.setPosition(pos);

            if (!sv.getVisible()) {
                sv.setVisible(true);
            }
        },

        /**
         * @param {jQuery} container
         * @param {google.maps.Map} map
         */
        showMapView: function (container, map) {
            const sv = map.getStreetView();
            const pos = this.originalMapPos(container);

            if (sv.getVisible()) {
                sv.setVisible(false);
            }

            map.setCenter(pos);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onMapButtonClick: function (container, evt) {
            $('.btn', container).removeClass('btn-selected');

            $(evt.currentTarget).addClass('btn-selected');

            return $('.map', container).data('gmap');
        },

        /**
         * @param {jQuery} container
         * @param {String} html
         */
        onMapLoaded: function (container, html) {
            const content = $(html);

            container.replaceWith(content);
            bindhq.initContainer(content);
            this.initMap(content);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} modal
         * @param {Object} insured
         */
        onEditAddressSuccess: function (container, modal, insured) {
            modal.modal('hide');
            bindhq.loader.showPartial(container);

            const config = {
                url: container.data('underwriting-map-url'),
                success: _.partial(this.onMapLoaded, container),
            };

            $.ajax(config);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} modal
         */
        onEditAddressModal: function (container, modal) {
            $('form', modal).modalAjax({
                success: _.partial(this.onEditAddressSuccess, container, modal),
            });
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onEditAddressClick: function (container, evt) {
            const url = $(evt.currentTarget).data('insured-address-url');
            const config = {
                callback: _.partial(this.onEditAddressModal, container),
            };

            bindhq.modalAjax.showUrl(url, config);

            evt.preventDefault();
        },

        /**
         * @param {jQuery} container
         */
        initMap: function (container) {
            const onButtonClick = _.partial(this.onMapButtonClick, container);

            container.on(
                'click',
                '.btn-streetview',
                _.compose(
                    _.partial(this.showStreetView, container),
                    onButtonClick,
                ),
            );

            container.on(
                'click',
                '.btn-mapview',
                _.compose(
                    _.partial(this.showMapView, container),
                    onButtonClick,
                ),
            );

            container.on(
                'click',
                '[data-js-hook="edit-insured-address"]',
                _.partial(this.onEditAddressClick, container),
            );
        },

        /**
         * @param {jQuery} container
         * @param {Object} file
         */
        renderDocumentViewer: function (container, file) {
            const viewerTpl = $('#underwriting-viewer-tpl').html();
            const html = bindhq.util.template(viewerTpl, { file: file });

            $('.viewer-wrap', container)
                .removeClass('viewer-wrap-hidden')
                .html(html)
                .parent()
                .find('.no-results')
                .remove();
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onDocumentChange: function (container, evt) {
            const select = $(evt.currentTarget);
            const source = select.hasClass('selector-single')
                ? $('option', select).get(0)
                : $(evt.currentTarget).select2('data').element[0];

            const data = source.dataset;
            const file = {
                name: data.fileName,
                browserViewable: data.fileBrowserViewable,
                downloadUrl: data.fileDownloadUrl,
                showUrl: source.value,
            };

            this.renderDocumentViewer(container, file);
        },

        /**
         * @param {jQuery} container
         * @param {Object} file
         */
        onDocumentAdded: function (container, file) {
            bindhq.loader.hidePartial(container);

            this.renderDocumentViewer(container, file);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onDocumentAddClick: function (container, evt) {
            const config = {
                url: container.data('add-document-url'),
                success: _.partial(this.onDocumentAdded, container),
                beforeSubmit: function () {
                    bindhq.loader.showPartial(container);
                },
            };

            bindhq.files.choose(config);
        },

        /**
         * @param {jQuery} container
         */
        initDocuments: function (container) {
            const onChange = _.partial(this.onDocumentChange, container);
            const onAddClick = _.partial(this.onDocumentAddClick, container);
            const selector = $('select.selector', container);

            selector.on('change', onChange).trigger('change');

            container.on(
                'click',
                '.add-document',
                bindhq.util.noDefault(onAddClick),
            );
        },

        /**
         * @param {Object} file
         *
         * @return {boolean}
         */
        hasUnderwritingTag: function (file) {
            return _.some(file.tags, function (tag) {
                return tag.type === 12;
            });
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onFileUploaded: function (container, evt) {
            const file = evt.file;

            if (this.hasUnderwritingTag(file)) {
                bindhq.tabs.refresh('underwriting');
            }
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            $('.widget-map', container).initEach(this.initMap);

            $('.widget-documents', container).initEach(this.initDocuments);

            $('body').on(
                'file_uploaded',
                _.partial(this.onFileUploaded, container),
            );
        },
    });
})();
