const { DateTime } = require('luxon');
const numeral = require('numeral');

(function () {
    'use strict';

    bindhq.ns('forms.rates');

    /**
     * @param {jQuery} container
     * @param {Object} carrierState
     * @param {Function} taxRater
     * @param {jQuery} overrideBox
     * @param {jQuery.Event} evt
     */
    bindhq.forms.rates.checkCarrierState = function (
        container,
        carrierState,
        taxRater,
        overrideBox,
        evt,
    ) {
        if (evt && carrierState.val()) {
            bindhq.forms.rates.removeTaxes(container);
            taxRater();
        }
    };

    /**
     * @param {jQuery} container
     * @param {Object} carrierState
     * @param {Object} data
     */
    bindhq.forms.rates.fillTaxes = function (container, carrierState, data) {
        const currencyFormat = '0.00';
        const rateMinEp = numeral($('.rate-min-ep', container).val()).divide(
            100,
        );
        const rateAgentCommission = numeral(
            $('.rate-avg-comm', container).val(),
        ).divide(100);
        const items = $('.rates [data-tax-or-fee]', container);
        const sum = function (items) {
            const total = numeral(0);

            items.each(function () {
                const value = $(this)
                    .closest('.collection-item')
                    .find('.line-item-amount input')
                    .val();

                total.add(numeral(value));
            });

            return total;
        };

        // calculate deposit

        const premiums = items.filter(bindhq.forms.rates.isPremium);
        const fees = items.filter(bindhq.forms.rates.isFee);
        const depositDue = numeral(0)
            .add(sum(premiums).multiply(rateMinEp))
            .add(sum(fees))
            .subtract(sum(premiums).multiply(rateAgentCommission));

        if ('mga' === container.find('.taxes-collected-by').val()) {
            _.each(data.rates, function (rate) {
                const amount = numeral(rate.amount.amount);
                depositDue.add(amount);
            });
        }

        $('.deposit-due', container).val(depositDue.format(currencyFormat));

        // remove existing taxes

        bindhq.forms.rates.removeTaxes(container);

        // add new taxes

        const collectionContainer = container.find('.rates [data-prototype]');
        const taxes = [];

        _.each(data.rates, function (rate) {
            const item = bindhq.forms.collection.inline.addBlankItem(
                container,
                collectionContainer,
            );
            const amount = numeral(rate.amount.amount);

            item.find('.line-item-amount input').val(
                amount.format(currencyFormat),
            );
            item.find('[data-tax-or-fee]').select2('data', {
                id: bindhq.orn.fromString(rate.tax.orn).id,
                name: rate.tax.name,
            });

            taxes.push(item);
        });
    };

    /**
     * @return {jQuery}
     */
    bindhq.forms.rates.toCollectionItem = function () {
        return $(this).closest('.collection-item');
    };

    /**
     * @param {jQuery} container
     */
    bindhq.forms.rates.removeTaxes = function (container) {
        $('.rates [data-tax-or-fee]', container)
            .filter(bindhq.forms.rates.isTax)
            .map(bindhq.forms.rates.toCollectionItem)
            .each(function () {
                $(this).remove();
            });
    };

    /**
     * @return {Boolean}
     */
    bindhq.forms.rates.isPremium = function () {
        return $(this).val() === 'owsy.item/premium';
    };

    /**
     * @return {Boolean}
     */
    bindhq.forms.rates.isTax = function () {
        return (
            $(this)
                .val()
                .match(/^owsy.item/) === null
        );
    };

    /**
     * @return {Boolean}
     */
    bindhq.forms.rates.isFee = function () {
        return (
            !bindhq.forms.rates.isPremium.apply(this) &&
            !bindhq.forms.rates.isTax.apply(this)
        );
    };

    bindhq.forms.rates.taxRater = function (
        container,
        carrierStateInput,
        depositInput,
        termEffectiveInput,
    ) {
        const carrierInput = $('.carrier', container);
        const carrierId = carrierInput.hasClass('select2-container')
            ? carrierInput.select2('val')
            : carrierInput.val();
        const carrierStateId = carrierStateInput.select2('val');
        const termEffective = termEffectiveInput.val();

        if (carrierId && carrierStateId && termEffective) {
            const items = $('.rates [data-tax-or-fee]', container)
                .filter(function () {
                    return !bindhq.forms.rates.isTax.apply(this);
                })
                .map(function () {
                    return {
                        item: $(this).val(),
                        amount: $(this)
                            .closest('.collection-item')
                            .find('.line-item-amount input')
                            .val(),
                    };
                })
                .toArray();

            if (items.length !== 0) {
                const config = {
                    method: 'GET',
                    url:
                        '/api/v1/carriers/' +
                        carrierId +
                        '/states/' +
                        carrierStateId +
                        '/rates',
                    data: {
                        transaction_type: container.data('transaction-type'),
                        term_effective: (termEffective
                            ? DateTime.fromISO(termEffective.substring(0, 10))
                            : DateTime.now()
                        ).toFormat('y-MM-dd'),
                        items: items,
                    },
                    success: function (data) {
                        bindhq.forms.rates.fillTaxes(
                            container,
                            carrierStateInput.select2('data'),
                            data,
                        );
                    },
                };

                $.ajax(config);
            }
        }
    };

    bindhq.forms.rates.stateSelectInit = function (container) {
        const carrierStateInput = $('input.carrierstate', container);
        const depositInput = $('.deposit-due', container).first();
        const overrideBox = $('.override-taxes', container).first();
        const termEffectiveInput = $('.term-effective', container).first();
        const taxRater = _.debounce(
            _.partial(
                bindhq.forms.rates.taxRater,
                container,
                carrierStateInput,
                depositInput,
                termEffectiveInput,
            ),
            1000,
        );
        const checkCarrierState = _.partial(
            bindhq.forms.rates.checkCarrierState,
            container,
            carrierStateInput,
            taxRater,
            overrideBox,
        );

        carrierStateInput.on('change', checkCarrierState);
        overrideBox.on('change', checkCarrierState);

        checkCarrierState();
    };

    bindhq.forms.rates.initLineItems = function (container) {
        const form = container.closest('form');
        const carrierStateInput = $('input.carrierstate', form);
        const termEffectiveInput = $('.term-effective', form);

        const updateTaxesAndFees = function () {
            const carrierId = $('input.carrier', form).val();
            const carrierStateId = carrierStateInput.val();
            const termEffective = termEffectiveInput.val();

            $('.rates [data-tax-or-fee]').each(function () {
                const url = $(this)
                    .data('ajaxurl-pattern')
                    .replace(/{carrierId}/, carrierId)
                    .replace(/{carrierStateId}/, carrierStateId);

                $(this).data(
                    'ajaxurl',
                    url +
                        '?term_effective=' +
                        (termEffective
                            ? DateTime.fromISO(termEffective.substring(0, 10))
                            : DateTime.now()
                        ).toFormat('y-MM-dd'),
                );
            });
        };

        container.on(
            'bindhq.forms.collection.inline.blank_item_added',
            updateTaxesAndFees,
        );

        carrierStateInput.change(updateTaxesAndFees);
        termEffectiveInput.change(updateTaxesAndFees);

        updateTaxesAndFees();
    };

    bindhq.forms.rates.init = function () {
        const container = $(this);
        const autofill = _.partial(
            bindhq.forms.rates.taxRater,
            container,
            $('input.carrierstate', container),
            $('.deposit-due', container).first(),
            $('.term-effective', container).first(),
        );

        bindhq.forms.rates.stateSelectInit(container);

        $(container).on(
            'click',
            '.btn-autofill',
            bindhq.util.noDefault(autofill),
        );

        bindhq.forms.rates.initLineItems(container);
    };
})();
