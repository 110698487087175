(function () {
    'use strict';

    bindhq.ns('carrierPrograms');

    /**
     * @param {jQuery} container
     */
    bindhq.carrierPrograms.init = function (container) {
        const displayArea = container.get(0);

        bindhq.gondola.initProgramsClient(displayArea);
    };
})();
