(function () {
    'use strict';

    bindhq.ns('applications.carriers');

    bindhq.applications.carriers.errorTemplate = bindhq.util.template(
        '<div class="alert alert-error">' +
            'Sorry, but there was an error, check all rules are valid...  <%= message %>' +
            '</div>',
    );

    /**
     * Creates a list element for a carrier
     */
    bindhq.applications.carriers.createCarrier = function (id, name, score) {
        const li = $('<li></li>').attr('data-carrier', id);
        const label = $('<label></label>').html(name + ':');
        const span = $('<span></span>').html(score);

        return li.append(label).append(span);
    };

    /**
     * Creates the list of all carriers
     */
    bindhq.applications.carriers.onScoringSuccess = function (
        data,
        textStatus,
        jqXHR,
    ) {
        const container = $(this);
        const ul = $('<ul></ul>').addClass('name-val');

        $.each(data, function (i, carrier) {
            ul.append(
                bindhq.applications.carriers.createCarrier(
                    carrier.carrier.id,
                    carrier.carrier.name,
                    carrier.score,
                ),
            );
        });

        container.html(ul);
    };

    bindhq.applications.carriers.onScoringComplete = function (
        textStatus,
        jqXHR,
    ) {
        const container = $(this);
        container.siblings('.progress').addClass('hide');
    };

    bindhq.applications.carriers.onScoringError = function (
        xhr,
        textStatus,
        message,
    ) {
        const error = bindhq.applications.carriers.errorTemplate({
            message: message,
        });

        $(this).empty().html(error);
    };

    /**
     * Handler to show suggested carriers in order
     */
    bindhq.applications.carriers.onSuggestClick = function (event) {
        const route = event.target.href;
        const context = $('.carriers-result');

        // show progress bar and clear any existing results
        context.siblings('.progress').removeClass('hide');
        context.html('');

        $.ajax({
            url: route,
            context: context,
            success: bindhq.applications.carriers.onScoringSuccess,
            complete: bindhq.applications.carriers.onScoringComplete,
            error: bindhq.applications.carriers.onScoringError,
        });

        event.preventDefault();
        event.stopPropagation();
    };

    /**
     * Init the suggest carrier button
     */
    bindhq.applications.carriers.initSuggest = function () {
        const container = $(this);
        const onClick = bindhq.applications.carriers.onSuggestClick;

        $('a.btn', container).click(onClick);
    };

    /**
     * Initialise Rules engine scoring
     */
    bindhq.applications.carriers.init = function () {
        $('.carriers-suggest', this).each(
            bindhq.applications.carriers.initSuggest,
        );
    };
})();
